
import {Component, Vue} from 'vue-property-decorator';
import SplitLayout from "@/components/SplitLayout.vue";
import GTDTools from "@/components/GTDTools.vue"; // @ is an alias to /src

@Component({
  components: {
    GTDTools,
    SplitLayout,
  },
})
export default class Landing extends Vue {
}
